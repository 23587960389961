import React, {useState} from 'react';
import {
  useHistory,
} from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { PageHeader, Button, Row, Col, Card, Avatar, Spin } from 'antd';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';

import AddReport from '../AddReport';

import './ReportSection.css';


const REPORTS = gql`
  query GetReports {
    reports {
      id
      firstName
      lastName
    }
  }
`;

const { Meta } = Card;

function ReportSection() {
  const history = useHistory();
  const { loading, error, data } = useQuery(REPORTS);
  const [isOpen, setModalOpen] = useState(false);

  if (error) return <p>Error :(</p>;

  return (
    <Spin spinning={loading}>
      <div className="Reports">
        <PageHeader 
          title="My reports"
          backIcon={false}
          extra={[
            <Button onClick={() => setModalOpen(true)} key="add">Add a report</Button>
          ]}
        />
        <div className="Reports-list">
          <Row gutter={[16, 16, 16, 16]}>
            {data && data.reports.map((report) => (
              <Col span={8} key={report.id}>
                <Card
                  actions={[
                    <SettingOutlined key="setting" />,
                    <EditOutlined key="edit" onClick={() => history.push(`/dashboard/${report.id}`)} />,
                    <EllipsisOutlined key="ellipsis" />,
                  ]}
                >
                  <Meta
                    avatar={
                      <Avatar>{report.firstName.substring(0,1) + report.lastName.substring(0,1)}</Avatar>
                    }
                    title={report.firstName + " " + report.lastName}
                    description="Software Engineer I"
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <AddReport isOpen={isOpen} toggleOpen={setModalOpen} />
      </div>
    </Spin>
  );
}

export default ReportSection;
