export const PostType = {
  NOTEWORTHY: 'NOTEWORTHY',
  DEVELOPMENT: 'DEVELOPMENT'
}

export const POST_TYPE_ICON = {
  [PostType.NOTEWORTHY]: 'ri-arrow-up-fill',
  [PostType.DEVELOPMENT]: 'ri-arrow-down-fill'
}

export const POST_TYPE_COLOR = {
  [PostType.NOTEWORTHY]: '#FFFF00',
  [PostType.DEVELOPMENT]: '#FF0000'
}