import React from 'react';
import {
  useHistory
} from "react-router-dom";

import {Layout, Menu} from 'antd';
import { useAuth0 } from '../AuthProvider';
import { Button } from 'antd';
import logo from '../logo.svg';

import './Header.css';

function Header() {
  const history = useHistory();
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { Header } = Layout;
  return (
    <Header className="Header">
      <img src={logo} className="Header-logo" alt="Lookout" />
      <Menu theme="dark" mode="horizontal" style={{float: 'right'}} selectable={false}>
        {!isAuthenticated && (
          <Menu.Item key="1">
            <Button type="primary" onClick={() => loginWithRedirect({
                redirect_uri: `${window.location.origin}/dashboard`
              })}
            >
              Log In
            </Button>
          </Menu.Item>
        )}
        {isAuthenticated && (
            <Menu.Item key="1" onClick={() => history.push('/dashboard')}>
              Dashboard
            </Menu.Item>
        )}
        {isAuthenticated && (
            <Menu.Item key="2" onClick={() => logout()}>
              Log out
            </Menu.Item>
        )}
      </Menu>
    </Header>
  );
}

export default Header;
