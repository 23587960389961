import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Form, Input, Modal } from 'antd';

import { useAuth0 } from '../AuthProvider';

import './AddReport.css';


const ADD_REPORT = gql`
  mutation CreateOneReport($firstName: String!, $middleName: String, $lastName: String!, $manager: String!) {
    createOneReport(data: {
      firstName: $firstName,
      middleName: $middleName,
      lastName: $lastName,
      manager: $manager,
    }) {
      id
      firstName
      middleName
      lastName
    }
  }
`;

function AddReport({isOpen, toggleOpen}) {
  const [addReport] = useMutation(ADD_REPORT);
  const { user } = useAuth0();
  const [form] = Form.useForm();
  return (
    <Modal
      visible={isOpen}
      title="Add a report"
      onOk={async () => {
        const values = await form.validateFields()
        addReport({ 
          variables: { 
            firstName: values.firstName,  
            middleName: values.middleName, 
            lastName: values.lastName,
            manager: user.email,
          },
          refetchQueries: ['GetReports']
        });
        toggleOpen(false);
        form.resetFields();
      }}
      onCancel={() => toggleOpen(false)}
      className="ReportSection-modal"
      overlayClassName="ReportSection-overlay"
    > 
      <Form
        form={form}
        onFinish={(values) => {
          addReport({ 
            variables: { 
              firstName: values.firstName,  
              middleName: values.middleName, 
              lastName: values.lastName,
              manager: user.email,
            },
            refetchQueries: ['GetReports']
          });
          form.resetFields();
        }}
      >
        <Form.Item
          label="First name"
          name="firstName"
          rules={[
            {
              required: true,
              message: 'Please provide a valid first name',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Middle name"
          name="middleName"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="lastName"
          rules={[
            {
              required: true,
              message: 'Please provide a valid last name',
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* {({ handleSubmit, form }) => (
          <form onSubmit={async (event) => {
            await handleSubmit(event);
            onSubmit();
            form.reset();
          }}>
              <div className="AddReport-fields">
                <div className="AddReport-field">
                  <Field
                    name="firstName"
                    component={TextInput}
                    label="First Name"
                  />
                </div>
                <div className="AddReport-field">
                  <Field
                    name="middleName"
                    component={TextInput}
                    label="Middle Name"
                  />
                </div>
                <div className="AddReport-field">
                  <Field
                    name="lastName"
                    component={TextInput}
                    label="Last Name"
                  />
                </div>
              </div>
              <div className="AddReport-submit">
                  <Button type="submit">Add report</Button>
              </div>
              
          </form>
        )} */}
      </Form>
    </Modal>
  );
}

export default AddReport;
