import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../AuthProvider";

const PrivateRoute = ({ children, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: {targetUrl: window.location.pathname}
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  return (
    <Route path={path} {...rest} >
      {isAuthenticated === true ? (
        {...children}
      ) : null}
    </Route>
  );
};

export default PrivateRoute;