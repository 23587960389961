import React from 'react';

import {Layout, Row, Col} from 'antd';

import './Footer.css';

function Footer() {
  const { Footer } = Layout;
  return (
    <Footer className="Footer">
      <Row justify="space-between">
        <Col>
        </Col>
        <Col>
          © Copyright 2020 Lookout, Inc. All rights reserved.
        </Col>
      </Row>
    </Footer>
  );
}

export default Footer;
