import React from 'react';
import { useParams } from "react-router-dom";
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Form, Input, Radio, Button } from 'antd';

import { PostType } from '../constants';

import "./NoteEditor.css";

const ADD_POST = gql`
  mutation CreateOnePost($id: Int!, $postType: PostType!, $text: String!) {
    createOnePost(data: {
      postType: $postType,
      text: $text,
      report: {
        connect: {
          id: $id,
        },
      },
    }) {
      id
      text
      createdAt
      updatedAt
      report {
        firstName
        lastName
      }
    }
  }
`;

const { TextArea } = Input;

function NoteEditor() {
  const [addPost] = useMutation(ADD_POST);
  const { id } = useParams();
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      onFinish={(values) => {
        addPost({ 
          variables: { postType: values.postType,  text: values.editor, id: parseInt(id)},
          refetchQueries: ['GetPostForReport']
        });
        form.resetFields();
      }}
    >
      <Form.Item
        // label="Username"
        name="postType"
        rules={[
          {
            required: true,
            message: 'Please select the type of feedback you would like to give',
          },
        ]}
      >
        <Radio.Group>
          <Radio.Button value={PostType.NOTEWORTHY}>Noteworthy</Radio.Button>
          <Radio.Button value={PostType.DEVELOPMENT}>Development</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="editor"
        rules={[
          {
            required: true,
            message: 'Please provide feedback when submitting',
          },
        ]}
      >
        <TextArea rows={4} placeholder="Enter feeback here..." />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  )
}

export default NoteEditor;