import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost';
import { setContext } from "apollo-link-context";
import { useAuth0 } from "../AuthProvider";

function GraphqlProvider({children}) {
  // used state to get accessToken through getTokenSilently(), the component re-renders when state changes, thus we have
  // our accessToken in apollo client instance.
  const {getIdTokenClaims, loading} = useAuth0();
  if (loading) {
    return "Loading...";
  }

  // for apollo client
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL,
    credentials: 'include'
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getIdTokenClaims();
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token.__raw}`
        }
      };
    } else {
      return {
        headers: {
          ...headers
        }
      };
    }
    
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client} >
      {children}
    </ApolloProvider>
  );
}

export default GraphqlProvider;
