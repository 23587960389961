import React from 'react';
import {
  Switch,
} from "react-router-dom";

import PostSection from '../PostSection';
import ReportSection from '../ReportSection';
import PrivateRoute from '../PrivateRoute';

import './Dashboard.css';

function Dashboard() {
  return (
    <Switch>
      <PrivateRoute path="/dashboard/:id">
        <PostSection />
      </PrivateRoute>
      <PrivateRoute path="/">
        <ReportSection />
      </PrivateRoute>
    </Switch>
  );
}

export default Dashboard;
