import React from 'react';
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { PageHeader, Avatar, List, Tag } from 'antd';
import moment from 'moment';

import NoteEditor from '../NoteEditor';

import './PostSection.css';


const POSTS = gql`
  query GetPostForReport($id: Int!) {
    report(
      id: $id
    ) {
      firstName
      lastName
      posts(orderBy: {
        createdAt: desc
      }) {
        id
        text
        postType
        createdAt
      }
    }
  }
`;

function PostSection() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(POSTS, {
    variables: {id: parseInt(id)}
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  console.log(data.report.firstName.substring(0,1) + data.report.lastName.substring(0,1));
  return (
    <div className="PostSection">
      <PageHeader 
        avatar={{
          children: data.report.firstName.substring(0,1) + data.report.lastName.substring(0,1)
        }}
        title={data.report.firstName + " " + data.report.lastName}
        backIcon={false}
      />
      <div>
        <NoteEditor />
      </div>
      <List
        itemLayout="vertical"
        size="large"
        dataSource={data.report.posts}
        renderItem={item => (
          <List.Item key={item.id}>
            <List.Item.Meta
              avatar={<Avatar />}
              title={<Tag>{item.postType}</Tag>}
              description={moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
            />
            {item.text}
          </List.Item>
        )}
      />
    </div>
  );
}

export default PostSection;
