import React from 'react';
import { Button, Typography, Row, Col, Space} from 'antd';

import logo from './undraw_bookmarks_r6up.svg';

import './HomePage.css';

const { Title } = Typography;

function HomePage() {
  return (
    <div className="HomePage">
      <Row>
        <Col span={12}>
          <div className="HomePage-callout">
            <Title>Performance management in the tools you use every day</Title>
            <Title level={4} type="secondary">Lookout allows you to easily track employee performance and help your team be more productive</Title>
            <div className="HomePage-actions">
              <Space>
                <Button type="primary" size="large">Add Chrome Extension</Button>
                <Button size="large">Schedule a demo</Button>
              </Space>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <img src={logo} className="HomePage-image" alt="Bookmark in Chrome" />
        </Col>
      </Row>
            
    </div>
  );
}

export default HomePage;
