import React from 'react';
import {
  Router,
  Switch,
  Route
} from "react-router-dom";
import { Layout } from 'antd';

import { Auth0Provider } from "./AuthProvider";
import GraphqlProvider from './GraphqlProvider';
import Dashboard from './Dashboard';
import Header from './Header';
import Footer from './Footer';
import HomePage from './HomePage';

import config from "./auth_config.json";
import history from "./utils/history";

import 'remixicon/fonts/remixicon.css';
import 'antd/dist/antd.css';
import './App.css';

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const { Content } = Layout;

function App() {
  
  return (
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      <GraphqlProvider>
        <Router history={history}>
          <Layout className="App">
            <Header />
            <Content className="App-body">
              <Switch>
                <Route path="/dashboard">
                  <Dashboard />
                </Route>
                <Route path="/">
                  <HomePage />
                </Route>
              </Switch>
            </Content>
            <Footer />
          </Layout>
        </Router>
      </GraphqlProvider>
    </Auth0Provider>
  );
}

export default App;
